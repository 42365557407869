<template>
	<v-container>
		<!-- Button Add if user logged -->
		<v-row v-if="isLoggedIn">
			<v-col>
				<v-btn color="primary" dark to="/walkposts/create-edit"
					><v-icon small> mdi-plus </v-icon>{{ $t('add') }}
				</v-btn>
			</v-col>
		</v-row>
		<v-row v-else>
			<v-col>
				<p>Влезте в профила си за да публикувате обява.</p>
			</v-col>
		</v-row>

		<v-row>
			<v-col
				sm="6"
				md="4"
				xl="3"
				v-for="walkPost in walkPosts"
				:key="walkPost._id"
			>
				<v-card class="mx-auto" min-width="185px">
					<v-img
						class="blue--text align-end"
						height="300px"
						:src="`${walkPost.image}`"
					>
						<v-card-subtitle>{{ convertDate(walkPost) }}</v-card-subtitle>
					</v-img>

					<v-card-title> {{ walkPost.title }} </v-card-title>
					<v-card-text class="text--primary">
						<div class="text-preview">{{ walkPost.description }}</div>

						<div class="my-2">{{ walkPost.city }} {{ walkPost.district }}</div>
					</v-card-text>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							v-if="isOwner(walkPost)"
							color="secondary"
							dark
							:to="{ name: 'WalkPostCreateEdit', params: { id: walkPost._id } }"
						>
							{{ $t('edit') }}
						</v-btn>

						<v-btn color="primary" dark @click="readMore(walkPost)">
							{{ $t('open') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>

		<!-- Loader -->
		<div v-if="isLoading" class="text-center">
			<v-progress-circular indeterminate color="primary"></v-progress-circular>
		</div>
	</v-container>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';

export default {
	name: 'Blog',

	data() {
		return {
			url: process.env.VUE_APP_BASE_URL || 'http://localhost:5000/api/v1',
			isLoading: false,
			walkPosts: [],
		};
	},
	computed: {
		...mapState('auth', ['user']),
		...mapGetters('auth', ['isLoggedIn']),
	},
	methods: {
		isOwner(post) {
			return this.user &&
				(this.user._id === post.user || this.user.role === 'admin')
				? true
				: false;
		},
		convertDate(post) {
			return moment(post.createdAt).format('DD.MM.YYYY');
		},
		readMore(walkPost) {
			this.$router.push({ name: 'WalkPost', params: { id: walkPost._id } });
		},
		async getWalkPosts() {
			this.isLoading = true;

			try {
				const res = await this.$http.get(`${this.url}/walkPosts`);

				this.walkPosts = res.data.data;
			} catch (error) {
				console.error(error);
			} finally {
				this.isLoading = false;
			}
		},
	},
	mounted() {
		this.getWalkPosts();
	},
};
</script>

<style lang="scss" scoped>
img {
	height: 200px;
	width: 200px;
}

.v-card__title {
	word-break: normal !important;
}

.v-card__text {
	padding-bottom: 0 !important;
}

.text-preview {
	white-space: nowrap;
	word-break: normal;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
